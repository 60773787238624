<template>
  <div>
    <ShippingForm v-for="pkgEntity in shipment.packages" :key = "pkgEntity.id" :shipment="shipment" :receipt="receipts[pkgEntity.receiptCode]" :entity="pkgEntity">
    </ShippingForm>
  </div>
</template>
<script>
import ShippingForm from '@/pages/print/components/ShippingForm'
import ShipmentService from '@/service/ShipmentService'
import formatter from '@/utils/formatter'
import adjustment from '@/utils/adjustment'
import { Consts } from '@/utils/consts'

export default {
  components: {ShippingForm},
  data() {
    return {
      shipment: {},
      receipts: {},
      formatter,
    }
  },
  mounted() {
    if(this.$route.params.id) {
      ShipmentService.get(this.$route.params.id).then(response => {
        this.shipment = adjustment.adjustShipment(response);
        const receipts = this.shipment.receipts.reduce((total, receipt) => {
          total[receipt.receiptCode] = receipt;
          return total;
        },{});
        this.receipts = receipts;
      });
    }
  },
  methods : {
    onChangeSelection(event) {
      this._calculateWeights();
      console.log(event)
    },
    _calculateWeights() {
      this.shipment.weightLbs = this.allPackages[1].reduce((prev, cur) => prev + cur.weightLbs, 0);
      this.shipment.weightKg = this.shipment.weightLbs * Consts.POUND2KG;
    },
    onSave() {
      this.shipment.packages = this.allPackages[1].map(pkg => pkg.id);
      if(this.shipment.id) {
        ShipmentService.update(this.shipment.id, this.shipment).then(response => this.shipment = adjustment.adjustShipment(response));
      } else {
        ShipmentService.create(this.shipment).then(response => {this.shipment = adjustment.adjustShipment(response)}).catch(e => console.log(e));
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.package-item {
	display: flex;
	align-items: center;
	padding: .5rem;
	width: 100%;

	img {
		width: 75px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin-right: 1rem;
	}

	.package-list-detail {
		flex: 1 1 0;
	}

	.package-list-action {
		display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .package-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .package-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }
}

@media screen and (max-width: 576px) {
    .package-item {
        flex-wrap: wrap;

        .image-container {
            width: 100%;
            text-align: center;
        }

        img {
            margin: 0 0 1rem 0;
            width: 100px;
        }
    }
}
</style>
